import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Use circular avatars to identify an author in the Hero Block section of an
article.`}</p>
    <FigmaEmbed url="https%3A%2F%2Fwww.figma.com%2Ffile%2FeVuNlIBZh5wbDiM4blDaEb%2FChameleon-Best-Practices%3Fnode-id%3D258%253A43" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}</h2>
    <p>{`Avatars are available in 4 sizes:`}</p>
    <ul>
      <li parentName="ul">{`Small (32 x 32 px)`}</li>
      <li parentName="ul">{`Medium (40 x 40 px)`}</li>
      <li parentName="ul">{`Large (48 x 48 px)`}</li>
      <li parentName="ul">{`XLarge (64 x 64 px)`}</li>
    </ul>
    <FigmaEmbed url="https%3A%2F%2Fwww.figma.com%2Ffile%2FeVuNlIBZh5wbDiM4blDaEb%2FChameleon-Best-Practices%3Fnode-id%3D251%253A1143" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <h3 {...{
      "id": "default-avatar",
      "style": {
        "position": "relative"
      }
    }}>{`Default avatar`}</h3>
    <p>{`When no image is available, use a generic fallback image.`}</p>
    <FigmaEmbed url="https%3A%2F%2Fwww.figma.com%2Ffile%2FeVuNlIBZh5wbDiM4blDaEb%2FChameleon-Best-Practices%3Fnode-id%3D251%253A1169" mdxType="FigmaEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      